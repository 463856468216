@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital@1&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.smoothScroll {
  scroll-behavior: smooth;
}

/* .trails-main {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 5;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .trails-text {
  position: relative;
  width: 100%;
  height: 80px;
  line-height: 80px;
  color: rgb(66, 61, 63);
  font-size: 5em;
  font-weight: 800;
  text-transform: uppercase;
  will-change: transform, opacity;
  overflow: hidden;
}

.trails-text>div {
  overflow: hidden;
} */

::-webkit-scrollbar {
  width: 5px;
  background-color: #f0eaea;
}

::-webkit-scrollbar-thumb {
  background-color: #dacebf;
}

.canvas {
  position: fixed !important;
  top: 0px;
  left: 0px;
  width: 100vw !important;
  height: 100vh !important;
}

.circular-cursor {
  cursor: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100'><circle cx='50' cy='50' r='50' fill='rgba(255,255,255,0.5)'/><circle cx='50' cy='50' r='40' fill='white'/></svg>") 50 50, auto;
}

.TitleTextContainer {
  pointer-events: none;
  display: flex;
  flex-direction: column;
}

.textContainer {
  pointer-events: none;
  position: absolute;
}

.eye {
  height: 80%;
  width: auto;
  position: absolute;
}


.projectTextContainer {
  position: absolute;
}

.block_container {
  pointer-events: none;
}

/* .RowTextContainer {
  pointer-events: none;
  display: flex;
  flex-direction: colr;
  width: 100%;
} */

.home {
  background: #E2DDD3;
  height: 100%;
}

.polus_bg {
  background: linear-gradient(to bottom, #0a0711, #372653);
}

.airship_bg {
  background: rgb(112, 136, 200);
  background: linear-gradient(180deg, rgba(112, 136, 200, 1) 0%, rgba(133, 176, 219, 1) 28%);
}

.projects {
  height: 100%;
}

.scrollable-container {
  max-height: 300px;
  width: 500px;
}

.scrollable-container::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f500;
  scroll-behavior: smooth;
}

.scrollable-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #744f1d;
}

.dot {
  width: 1vh;
  height: 1vh;
  border-radius: 100px;
  margin: 0.3vh;
  /* padding: 5px; */

}

.TL_BGs_Container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1vh;
}

.TL_Tablets_Container {
  display: flex;
  /* justify-content: space-around; */
  width: 100%;
  gap: 1vh;
  margin-inline: auto;

}

.trailsText {
  font-weight: 400;
  letter-spacing: -0.05vh;
  font-family: 'Roboto';

  color: #2c2725;
  pointer-events: all;
  will-change: transform, opacity;
}

.error-message-container {
  position: fixed;
  left: 50%;
  top: 50%;
  height: auto;
  width: 30vh;
  /* background-color: #05ca0f; */
  transform: translate(-50%, -50%);
  z-index: 11;
  letter-spacing: -0.1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  color: #271d19a4;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 2.35vh;
  font-weight: 400;
  border-radius: 15px;
  padding-block: 2.5vh;
  background-color: #f0ede9b2;
  backdrop-filter: blur(3px);
  box-shadow: 0 1vh 5vh rgba(0, 0, 0, 0.164);
}

.emailBackdrop {
  backdrop-filter: blur(0.25vh);
}

.message-sent {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  letter-spacing: -0.1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  color: #271d19a4;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 2.35vh;
}

.emailFormContainer {
  height: auto;
  padding-block: 2.5vh;
  background-color: #f0ede9b2;
  backdrop-filter: blur(3px);
  box-shadow: 0 1vh 5vh rgba(0, 0, 0, 0.164);
  border-radius: 3vh;
  position: fixed;
  top: 50%;
  left: 50%;

  /* top: 150vh; */
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  /* top: 125vh */
}

.blur-container {
  height: 70vh;
  width: 45vh;
  /* padding: 20px; */
  background-color: rgba(117, 146, 179, 0);
  /* Adjust the alpha value to control the background opacity */
  border-radius: 10px;
  position: absolute;
  left: 50%;
  /* top: 150vh; */
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  top: 125vh
}

.triangle {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 75px solid red;
  /* Change 'red' to your desired color */
}

.overlay {
  z-index: 2;
  font-weight: 400;
  font-family: 'Roboto';
  font-size: 1.5em;
  letter-spacing: -0.05em;
  color: #644838b0;
  cursor: pointer;
  user-select: none;
  outline: none;
}

.overlay:focus {
  outline: none;
}

.bg-container {
  position: relative;
  width: 70% !important;
  height: 400px;
  margin: auto;
  margin-top: 55%;
}

.overlayContainer {
  position: absolute;
  overflow: hidden;
  z-index: 10;
  display: flex;
  align-content: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 200px;
  height: 60px;
  pointer-events: all;
  background-color: #f0ede9ce;
  user-select: none;

}



.close-button {
  border-radius: 200px;
  background-color: #eb1b1bce;
  backdrop-filter: blur(3px);
  cursor: pointer;
  height: 3vh;
  width: 3vh;
  margin-inline: auto 3vh;
  margin-bottom: 2vh;
}

.close-button:hover {
  background-color: #eb1b1b;
}

.error-close-button {
  border-radius: 100vh;
  background-color: #eb1b1bce;
  cursor: pointer;
  height: auto;
  padding-block: 0.5vh;
  width: 75%;
  font-weight: 400;
  font-family: 'Roboto';
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
}

.error-close-button:hover {
  background-color: #eb1b1b;
}



.selected {
  width: 10px;
  height: 10px;
  background-color: rgb(255, 246, 123);
  margin: 5px;
}

.notSelected {
  width: 10px;
  height: 10px;
  background-color: rgba(175, 175, 175, 0.692);
  margin: 5px;
}

.underline {
  text-decoration: underline;
  color: #5c4029;
  cursor: pointer;
}

.risk_projects {
  font-weight: 500;
  font-family: 'Inter';
  color: #271d19a4;
}

.bold {
  font-weight: 400;
  font-family: 'Inter';
  color: #69482c;
}

.br_3 {
  line-height: 3vh;
}

.br_5 {
  line-height: 3vh;
}

.overflowY {
  overflow-y: auto;
  overflow-x: hidden;
}

.overflowHidden {
  overflow-y: auto;
}

.NFTCardsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-block: 2.5vh 5vh;
}

.NFTCard {
  display: flex;
  flex-direction: column;
  width: 85%;
  color: #534841;
  font-weight: 500;
  font-family: 'Roboto';
  position: relative;
  margin-block: 2.5vh 0;
}

.top-input {
  height: 3.25vh;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: blur(0.01vh);
  border-radius: 100vh;
  /* margin-inline: auto; */
 
}

.email-error {
  text-indent: 1.25vh;
  height: 3vh;
  width: 100%;
  display: flex;
  font-size: 2vh;
  color: rgb(221, 13, 13);
  /* overflow: hidden; */
}

.email-button-area {
  margin-top: 2vh;
  height: 4vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* background-color: green; */
}
/* height: 3.25vh;
  width: 100%;
  border-radius: 2px;
  display: flex;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.253);
  border-radius: 100vh;
  margin-inline: auto; */
.bottom-textarea {
  height: 35vh;
  width: 100%;
  border-radius: 2px;
  display: flex;
  border-radius: 2vh;
  backdrop-filter: blur(0.01vh);
  background-color: rgba(255, 255, 255, 0);
  overflow: hidden;
}

textarea {
  width: 100%;
  height: 100%;
  margin-inline: auto;
  background-color: #effffd00;
  /* background-color: rgba(0, 4, 255, 0.486); */
  
  border-color: rgba(255, 0, 0, 0);
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 2.15vh;
  resize: none;
  outline: none;
}

input {
  width: 100%;
  height: 100%;
  margin-inline: auto;
  background-color: #effffd00;
  
  border-color: rgba(255, 0, 0, 0);
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 2.15vh;
  resize: none;
  outline: none;
}

button {
  margin-top: 1vh;
  border-radius: 1.5vh;
  height: 5vh;
  width: 10vh;
  border: none;
  outline: none;
  cursor: pointer;
  /* padding: 1vh 2vh; */
  color: #fff;
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-weight: 400;
  justify-content: center;
  margin-inline: auto 0;
}

.modern-button {
  background-color: #38ad2175;
  font-size: 2vh;
}

.modern-button:hover {
  background-color: #38ad219a;
}

.modern-button:active {
  background-color: #05ca0f;
}

.modern-button:disabled {
  background-color: #6fa171;
}

.close {
  cursor: pointer;
  font-family: 'Inter';
  font-weight: 500;

}

.inter {
  font-weight: 400;
  font-family: 'Inter';
  font-size: 1.5vh;
}

.tabs {
  mix-blend-mode: screen;
  z-index: 1;
  user-select: none;
}

.tablet {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


@media (min-width: 540px) {
  .logo {
    height: 50px;
    width: auto;
    mix-blend-mode: difference;
  }

}

.ClickHere {
  height: 7.5vh;
  width: auto;
  mix-blend-mode: screen;
}


.textCont {
  pointer-events: none;
  position: absolute;
  z-index: 20;
  width: 50vh;
  margin-top: 42.5vh;
}

@media (max-aspect-ratio: 5.5/10) and (max-width: 600px) {
  .textCont {
    width: 80vw;
  }
}

@media (max-aspect-ratio: 4.5/10) and (max-width: 500px) {
  .trailsText {
    font-size: 2.35vh;
  }
}

.trailerText {
  position: relative;
  width: 100%;
}

.trailsContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.75vh;
  /* margin-block: 0vh 1vh; */
}

.p_trailsContainer {
  width: 100%;
}

.scrollCont {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: center;
}

.project_h1 {
  line-height: 0%;
  letter-spacing: -0.2vh;
  font-weight: 400;
  font-family: 'Roboto';
  font-size: 3.5vh;
  color: #534841;
  pointer-events: all;
}

.project_h2 {
  letter-spacing: -0.2vh;
  font-weight: 400;
  font-family: 'Roboto';
  font-size: 3.5vh;
  color: #534841;
  pointer-events: all;
  will-change: transform, opacity;
}

.pSumText {
  font-weight: 400;
  letter-spacing: 0.025vh;
  line-height: 115%;
  font-family: 'Inter';
  font-size: 3.25vh;
  color: #534841;
  pointer-events: all;
  will-change: transform, opacity;
  text-indent: 0vh;
}

.LainExpoContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-block: 7.5vh 0;
  height: 5vh;
}

.LainImageContainer {
  position: relative;
  height: 80vh;
  width: 90%;
  margin-inline: auto;
  margin-block: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

}

.LainImages {
  position: relative;
  height: 100%;
  cursor: pointer;
}

.LainImageTitle {
  line-height: 25%;
  font-weight: 500;
  letter-spacing: 0.035vh;
  font-family: 'Roboto';
  font-size: 2.75vh;
  color: #534841;
  pointer-events: all;
  will-change: transform, opacity;
  margin-bottom: 2vh;
}


.LainImageText {
  font-weight: 400;
  letter-spacing: -0.025vh;
  font-family: 'Inter';
  font-size: 2.5vh;
  color: #534841;
  pointer-events: all;
  will-change: transform, opacity;
}

.pSumContainer {
  width: 85%;
  margin-inline: auto;
  margin-block: 12.5vh;
}

.project_page_container {
  /* position: absolute; */
  z-index: 15;
  height: auto;
  width: 95vw;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  /* background-color: blue; */
}

.dots_container {
  display: flex;
  flex-direction: row;
  height: auto;
  width: auto;
  justify-content: center;
  align-items: center;
  margin-inline: 1vh;
  position: absolute;
  right: 0;
}

.divider_bar {
  height: 0.1vh;
  background-color: #534841;
}

.spaced_divider {
  height: 0.1vh;
  background-color: #534841;
  margin-top: 2.5vh;
}

.contentContainer {
  height: auto;
  width: inherit;
  display: flex;
  flex-direction: column;
  margin-bottom: 5vh;
  /* background-color: #05ca0f; */
}

.bannerContainer {
  height: auto;
  width: inherit;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.banner {
  height: 30vh;
  width: auto;
}

.imageBannerContainer {
  width: 100%;
}

.contentContainerDiv {
  height: auto;
  width: 100%;
  padding: 0px;
}

.imageContentContainerDiv {
  height: auto;
  width: 100%;
}

.imageContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.contentContainerDivTitle {
  height: auto;
  width: 100%;
  /* margin-block: 5vh 0; */
  /* background-color: #493c35; 2c2725*/
}

.contentText {
  width: 100%;
  color: #534841;
  font-size: 2.5vh;
  font-weight: 400;
  font-family: 'Roboto';
  letter-spacing: 0.01em;
  line-height: 125%;
  text-align: left;
  pointer-events: all;
  margin: 0;
}

.LainClickHere {
  right: 0.5vh;
  top: 0.5vh;
  z-index: 10;
  background-color: #bebebe67;
  border-radius: 2vh;
  color: #ffffff;
  border: 0.1vh solid #ffffffb7;
  font-weight: 600;
}

.SC_ClickHere {
  right: 0.5vh;
  top: 0.5vh;
  z-index: 10;
  background-color: #cecece67;
  border-radius: 2vh;
  color: #ffffff;
  border: 0.1vh solid #ffffffb7;
}

.italicRobo {
  width: inherit;
  color: #534841;
  font-size: 2.25vh;
  font-weight: 400;
  font-family: 'Roboto';
  letter-spacing: -0.01em;
  text-align: left;
  pointer-events: all;
  font-style: italic;
}

.bannerText {
  color: white;
  font-family: 'Inter';
  letter-spacing: -0.01em;
  text-align: left;
  pointer-events: all;
}

.boldRobo {
  color: #534841f6;
  font-weight: 500;
  font-family: 'Roboto';
  letter-spacing: -0.01em;
  text-align: left;
  pointer-events: all;
}

.regRobo {
  color: #534841;
  font-weight: 400;
  font-family: 'Roboto';
  letter-spacing: -0.01em;
  text-align: left;
  pointer-events: all;
}

.thinRobo {
  color: #534841;
  font-size: 5vh;
  font-weight: 100;
  font-family: 'Roboto';
  letter-spacing: -0.01em;
  text-align: left;
  pointer-events: all;
}

@media (5/10 > aspect-ratio) {
  .emailFormContainer {
    width: 90%;
  }
  .project_page_container {
    width: 30vh;
  }

  .pSumText {
    font-size: 2.9vh;
  }
  .trailsText {
    font-size: 2.35vh;
    /* color:purple */
  }

  .textCont {
    width: 80vw;
  }

  .pSumContainer {
    width: 95%;
  }

  .thinRobo {
    font-size: 4vh;
  }

  .regRobo {
    font-size: 2.4vh;
  }

  .LainImageContainer {
    width: 100%;
  }
}

@media (6/10 > aspect-ratio >=5/10) {
  .emailFormContainer {
    width: 40vh;
  }
  .project_page_container {
    width: 40vh;

  }

  .pSumText {
    font-size: 2.95vh;
  }

  .pSumContainer {
    width: 95%;
  }

  .LainImageContainer {
    width: 100%;
  }

  .trailsText {
    font-size: 2.5vh;
    /* color: red */
  }

  .textCont {
    width: 80vw;
  }

}

@media (7/10 > aspect-ratio >=6/10) {
  .emailFormContainer {
    width: 50vh;
  }
  .project_page_container {
    width: 50vh;
  }

  .trailsText {
    font-size: 2.5vh;
    /* color: green; */
  }

  .textCont {
    margin-top: 50vh;
  }

  .pSumText {
    font-size: 3.15vh;
  }

  .banner {
    height: 32.5vh;
  }

  .LainImageContainer {
    width: 100%;
  }

}

@media (8/10 > aspect-ratio >=7/10) {
  .emailFormContainer {
    width: 60vh;
  }

  .trailsText {
    font-size: 2.6vh;
  }
  .project_page_container {
    width: 60vh;
  }

  .banner {
    height: 32.5vh;
  }

  .textCont {
    margin-top: 55vh;
  }

}

@media (9/10 > aspect-ratio >=8/10) {
  .emailFormContainer {
    width: 60vh;
  }
  .project_page_container {
    width: 70vh;
    
  }

  .trailsText {
    font-size: 2.6vh;
  }

  .contentContainerDivTitle {
    width: 40%;
    margin-block: 2vh 0;
  }

  .contentContainerDiv {
    width: 60%;
  }

  .contentContainer {
    flex-direction: row;
  }

  .banner {
    height: auto;
    width: 100%;
  }

  .NFTCardsContainer {
    flex-direction: row;
    margin-block: 2.5vh;
  }

  .NFTCard {
    width: 45%;
    margin-block: 0 2.5vh;

  }

  .imageContentContainerDiv {
    width: 49%;
  }

  .imageContentContainer {
    flex-direction: row;
  }

  .imageBannerContainer {
    margin-top: 0;
  }

  .TL_BGs_Container {
    gap: 2vh;
  }

  .spaced_divider {
    margin-top: 0vh;
  }

  .TL_Tablets_Container {
    width: 70%;
    gap: 3vh;
  }

  .LainImageContainer {
    width: 100%;
    height: 75vh;
    flex-direction: row;
  }



  .textCont {
    margin-top: 55vh;
  }

  .LainExpoContainer {
    width: 40%;
    margin-block: 0vh;
  }

  .LainImages {
    margin-left: 5vh;
  }
}

@media (1 > aspect-ratio >=9/10) {
  .emailFormContainer {
    width: 60vh;
  }

  .trailsText {
    font-size: 2.6vh;
  }

  .project_page_container {
    width: 80vh;
    
  }

  .pSumText {
    font-size: 3.5vh;
  }

  .contentContainer {
    flex-direction: row;
  }

  .contentContainerDivTitle {
    width: 40%;
    margin-block: 2vh 0;
  }

  .contentContainerDiv {
    width: 60%;
  }

  .banner {
    height: auto;
    width: 100%;
  }

  .NFTCardsContainer {
    flex-direction: row;
    margin-block: 2.5vh;
  }

  .NFTCard {
    width: 45%;
    margin-block: 0 2.5vh;
  }

  .imageContentContainerDiv {
    width: 47.5%;
  }

  .imageContentContainer {
    flex-direction: row;
  }

  .imageBannerContainer {
    margin-top: 0;
  }

  .TL_BGs_Container {
    gap: 2.5vh;
  }

  .spaced_divider {
    margin-top: 0vh;
  }

  .TL_Tablets_Container {
    width: 70%;
    gap: 4vh;
  }

  .LainImageContainer {
    width: 100%;
    height: 75vh;
    flex-direction: row;
  }



  .textCont {
    margin-top: 55vh;
  }

  .LainExpoContainer {
    width: 40%;
    margin-block: 0vh;
  }

  .LainImages {
    margin-left: 5vh;
  }
}

@media (aspect-ratio >=1) {
  .emailFormContainer {
    width: 60vh;
  }
  .project_page_container {
    width: 90vh;
  }

  .trailsText {
    font-size: 2.6vh;
  }

  .pSumText {
    font-size: 3.65vh;
  }

  .contentContainer {
    flex-direction: row;
  }

  .contentContainerDivTitle {
    width: 40%;
    margin-block: 2vh 0;
    height: 100%;

  }

  .contentContainerDiv {
    width: 60%;

  }

  .banner {
    height: auto;
    width: 100%;
  }


  .NFTCardsContainer {
    flex-direction: row;
    margin-block: 2.5vh;
  }

  .NFTCard {
    width: 45%;
    margin-block: 0 2.5vh;
  }

  .imageContentContainerDiv {
    width: 47.5%;
  }

  .imageContentContainer {
    flex-direction: row;
  }

  .imageBannerContainer {
    margin-top: 0;
  }

  .TL_BGs_Container {
    gap: 3vh;
  }

  .spaced_divider {
    margin-top: 0vh;
  }


  .TL_Tablets_Container {
    width: 70%;
    gap: 5vh;
  }

  .LainImageContainer {
    width: 90%;
    height: 75vh;
    flex-direction: row;
  }

  .textCont {
    margin-top: 55vh;
  }

  .LainExpoContainer {
    width: 40%;
    margin-block: 0vh;
  }

  .LainImages {
    margin-left: 5vh;
  }

}